<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
    />
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <AddNewContent name="lh-content-tvc-create" />

          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>

        <template slot="thead">
          <vs-th sort-key="title">ขื่อภาพยนต์ (TVC)</vs-th>
          <vs-th sort-key="asset_value">TVC View</vs-th>
          <vs-th sort-key="link_url">URL</vs-th>
          <vs-th sort-key="published_at">วันที่จะเผยแพร่ได้</vs-th>
          <vs-th sort-key="updated_at">วันที่จะแก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{ tr.title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{ tr.asset_value }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{ tr.link_url }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">{{ publicDateFormat(tr.published_at) }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.revision_id)">
                  <p class="brand-priority">
                  {{ updatedAtDatetimeFormat(tr.updated_at) }}
                </p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.revision_id)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.revision_id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.revision_id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import map from 'lodash/map'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import useTvcUtil from '@/use/useTvcUtil'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'Index',
  components: { PopupLanguage, AddNewContent, LhDropdownPerPage, CustomTable },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'tvc')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData } = useTvcUtil(ctx)

    crudFunction.routePrefix.value = 'lh-content-tvc'

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => ({
        ...item,
      }))
    })

    const handlerDuplicateContent = (langToDuplicationContent) => {
      crudFunction.handlerDuplicateContent(
        formData,
        prepareFormData,
        langToDuplicationContent,
      )
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    return {
      ...crudFunction,
      items,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      searchKeys: ['title', 'asset_value', 'link_url']
    }
  },
}
</script>

<style scoped></style>
